<template>
  <div class="GroupList page">
    <div class="topbottom">
      <a-button style="margin-right: 10px" type="primary" @click="handleAdd(1)"
        >添加默认分组</a-button
      >
      <a-button type="primary" @click="handleAdd(2)">添加非默认分组</a-button>
    </div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      rowKey="cate_id"
      :pagination="{
        current: page,
        total: total,
      }"
      @change="pageChange"
    >
      <template #is_default="{ text }">
        {{ text == 1 ? "默认分组" : "不是默认分组" }}
      </template>
      <template #talk_temp_id="{ text }">
        {{ text == 0 ? "" : text }}
      </template>
      <template #classify_id="{ text }">
        {{ handleFun(classify, text) }}
      </template>
      <template #operation2="{ record }">
        <a-switch
          v-model:checked="record.checked"
          @change="handleChange($event, record)"
        />
      </template>
      <template #operation="{ record }">
        <a-button
          v-if="record.is_default != 1"
          type="primary"
          size="small"
          @click="handleEdit(record)"
          style="margin-right: 4px"
          >编辑</a-button
        >
        <a-button size="small" @click="handleDelete(record.cate_id)"
          >删除</a-button
        >
      </template>
    </a-table>
    <a-modal
      centered
      :title="modalTitle"
      :maskClosable="false"
      cancelText="取消"
      okText="确定"
      v-model:visible="addVisible"
      :width="570"
      @ok="handleOk"
      @cancel="handleCancel"
      class="group-list"
    >
      <a-form layout="inline" :model="formState">
        <a-form-item label="名称" required v-if="formState.is_default == 2">
          <a-input
            placeholder="please select your zone"
            v-model:value="formState.cate_name"
          />
        </a-form-item>
        <a-form-item label="话术" required v-if="formState.is_default == 2">
          <a-select
            placeholder="please select your zone"
            v-model:value="formState.talk_temp_id"
            @change="talkTempIdChange"
          >
            <a-select-option
              v-for="item in list"
              :key="item.talk_temp_id"
              :value="item.talk_temp_id"
              >{{ item.talk_temp_name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="场景" required v-if="formState.is_default == 1">
          <a-cascader
            v-model:value="formState.classify_id"
            style="width: 183px"
            :options="options"
            placeholder="Please select"
            :fieldNames="{
              label: 'classify_name',
              value: 'classify_id',
              children: 'children',
            }"
          />
        </a-form-item>
        <a-form-item label="父级" required v-if="formState.is_default == 2">
          <a-tree-select
            v-model:value="formState.parent_id"
            style="width: 183px"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="parentIdArr"
            placeholder="Please select"
            :replaceFields="{
              title: 'cate_name',
              key: 'cate_id',
              value: 'cate_id',
              children: 'children',
            }"
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item label="显示">
          <a-switch v-model:checked="formState.check"></a-switch>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal
      centered
      title="删除"
      :maskClosable="false"
      cancelText="取消"
      okText="确定"
      :width="300"
      v-model:visible="deleteVisible"
      @ok="handleOkDelete"
    >
      <p>确认删除吗？</p>
    </a-modal>
    <a-modal
      centered
      title="编辑"
      :maskClosable="false"
      cancelText="取消"
      okText="确定"
      :width="400"
      v-model:visible="EditVisible"
      @ok="handleOkEdit"
    >
      <a-form>
        <a-form-item label="名称" required>
          <a-input v-model:value="changeName" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "@vue/runtime-core";
import {
  getGroupList,
  getGroupAdd,
  getSpeechGroup,
  getGroupDel,
  getGroupEdit,
} from "../../../service/grouplist";
import { getSpeechList } from "../../../service/SpeechcraftMark";
import { message } from "ant-design-vue";
// const dataSource = [
//   {
//     key: 1,
//     name: "胡彦斌",
//     age: 32,
//     address: "西湖区湖底公园1号",
//     children: [
//       {
//         key: 11,
//         name: "胡彦斌",
//         age: 32,
//         address: "西湖区湖底公园1号",
//       },
//       {
//         key: 12,
//         name: "胡彦斌",
//         age: 32,
//         address: "西湖区湖底公园1号",
//       },
//     ],
//   },
//   {
//     key: 2,
//     name: "胡彦祖",
//     age: 42,
//     address: "西湖区湖底公园1号",
//   },
// ];
const columns = [
  {
    title: "名称",
    dataIndex: "cate_name",
  },
  {
    title: "是否默认",
    dataIndex: "is_default",
    slots: { customRender: "is_default" },
  },
  {
    title: "话术id",
    dataIndex: "talk_temp_id",
    slots: { customRender: "talk_temp_id" },
  },
  {
    title: "场景",
    dataIndex: "classify_id",
    slots: { customRender: "classify_id" },
  },
  //   {
  //     title: "显示/隐藏",
  //     dataIndex: "operation2",
  //     slots: {
  //       customRender: "operation2",
  //     },
  //   },
  {
    title: "状态",
    // key: "operation",
    // width: 100,
    dataIndex: "operation2",
    slots: {
      customRender: "operation2",
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    fixed: "right",
    slots: {
      customRender: "operation",
    },
  },
];
export default {
  name: "GroupList",
  setup() {
    const state = reactive({
      page: 1,
      total:undefined,
      dataSource: [],
      classify: [],
      addVisible: false,
      deleteVisible: false,
      EditVisible: false,
      options: [],
      list: [],
      modalTitle: undefined,
      parentIdArr: [],
      cate_id: undefined,
      editCate_id: undefined,
      changeName: undefined,
      talktempid: undefined,
    });
    const formState = reactive({
      cate_name: undefined,
      is_default: undefined,
      talk_temp_id: undefined,
      classify_id: undefined,
      parent_id: undefined,
      check: true,
    });
    onMounted(() => {
      init();
    });
    const init = async () => {
      const res = await getGroupList({
        access_token: sessionStorage.getItem("token"),
        page: state.page,
        limit: 10,
      });
      console.log(res);
      state.dataSource = res.data.data.techCategory.list;
      state.total = res.data.data.techCategory.total;
      state.classify = res.data.data.classify;
    };
    const pageChange = (e) => {
      // console.log(e);
      state.page = e.current;
      init();
    };
    const handleEdit = (record) => {
      //   console.log(record);
      state.editCate_id = record.cate_id;
      state.EditVisible = true;
      state.changeName = record.cate_name;
      state.talktempid = record.talk_temp_id;
    };
    const handleOkEdit = async () => {
      const res = await getGroupEdit({
        access_token: sessionStorage.getItem("token"),
        cate_id: state.editCate_id,
        cate_name: state.changeName,
        talk_temp_id: state.talktempid,
      });
      console.log(res);
      if (res.data.status == 100000) {
        state.EditVisible = false;
        message.success(res.data.msg);
        init();
      }
    };
    const handleDelete = (cate_id) => {
      console.log(cate_id);
      state.deleteVisible = true;
      state.cate_id = cate_id;
    };
    const handleChange = async (e, record) => {
      console.log(e, record);
      const res = await getGroupEdit({
        access_token: sessionStorage.getItem("token"),
        cate_id: record.cate_id,
        check: e ? "1" : "2",
        talk_temp_id: record.talk_temp_id,
      });
      //   console.log(res);
      if (res.data.status == 100000) {
        message.success("更新状态成功");
        init();
      }
    };
    //确定添加
    const handleOk = async () => {
      if (formState.is_default == 1) {
        const params = {
          is_default: formState.is_default,
          classify_id: formState.classify_id[formState.classify_id.length - 1],
          check: formState.check ? 1 : 2,
        };
        const res = await getGroupAdd({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        if (res.data.status == 100000) {
          message.success(res.data.msg);
        } else if (res.data.status == "100089") {
          message.error(res.data.msg);
        }
      } else {
        const params = {
          cate_name: formState.cate_name,
          is_default: formState.is_default,
          talk_temp_id: formState.talk_temp_id,
          parent_id: formState.parent_id || 0,
          check: formState.check ? 1 : 2,
        };
        console.log(params);
        const res = await getGroupAdd({
          access_token: sessionStorage.getItem("token"),
          ...params,
        });
        if (res.data.status == 100000) {
          message.success(res.data.msg);
        } else if (res.data.status == "100089") {
          message.error(res.data.msg);
        }
      }
      state.addVisible = false;
      init();
      handleCancel();
    };
    const handleCancel = () => {
      // console.log(formState.cate_name)
      (formState.cate_name = undefined),
        (formState.is_default = undefined),
        (formState.talk_temp_id = undefined),
        (formState.classify_id = undefined),
        (formState.parent_id = undefined),
        (formState.check = true);
    };
    const handleAdd = (play) => {
      formState.is_default = play;
      if (play == 1) {
        state.modalTitle = "添加默认分组";
      } else {
        state.modalTitle = "添加非默认分组";
      }
      state.addVisible = true;
      getSpeechListFun();
      //   const res = await getList({
      //     access_token: sessionStorage.getItem("token"),
      //   });
      //   console.log(res);
      //   state.options = res.data.data.list;
    };
    const getSpeechListFun = async () => {
      const res = await getSpeechList({
        access_token: sessionStorage.getItem("token"),
      });
      console.log(res);
      state.options = res.data.data.classify_list;
      state.list = res.data.data.list;
    };
    const handleFun = (arr, str) => {
      let res;
      //   console.log(arr, str);
      arr.forEach((item) => {
        if (str === item.classify_id) {
          res = item.classify_name;
          return;
        }
      });
      //   console.log(res)
      return res;
    };
    const talkTempIdChange = async (e) => {
      console.log(e);
      formState.parent_id = "";
      const res = await getSpeechGroup({
        access_token: sessionStorage.getItem("token"),
        talk_temp_id: e,
      });
      console.log(res);
      state.parentIdArr = res.data.data;
    };
    //确认删除
    const handleOkDelete = async () => {
      const res = await getGroupDel({
        access_token: sessionStorage.getItem("token"),
        cate_id: state.cate_id,
      });
      console.log(res);
      if (res.data.status == 100000) {
        message.success("删除成功");
      }
      state.deleteVisible = false;
      init();
    };
    return {
      columns,
      ...toRefs(state),
      handleDelete,
      handleEdit,
      handleChange,
      handleFun,
      handleAdd,
      handleOk,
      //   options,
      formState,
      handleCancel,
      talkTempIdChange,
      handleOkDelete,
      handleOkEdit,pageChange
    };
  },
};
</script>

<style>
.group-list .ant-form-item .ant-form-item-label {
  min-width: 55px;
}
.group-list .ant-form-item:nth-child(2n + 1) {
  margin-right: 30px;
}
.group-list .ant-form-item {
  margin-bottom: 24px;
}
.group-list .ant-form-item:nth-last-child(1) {
  margin-bottom: 0px;
}
.group-list .ant-form-item:nth-last-child(2) {
  margin-bottom: 0px;
}
</style>
<style scoped>
.GroupList {
  padding: 15px;
  overflow: auto;
}
.topbottom {
  margin-bottom: 15px;
}
/* .row {
  display: flex;
  justify-content: space-between;
} */
.ant-select,
.ant-input {
  width: 183px;
}
</style>