import http from "../axios/http.js"

//知识库分组列表
export let getGroupList = (params) => {
    return http.get("/technology/group_list", { params })
}
//添加知识库分组
export let getGroupAdd = (params) => {
    return http.post("/technology/group_add", params)
}
//根据话术id去获取分组列表
export let getSpeechGroup = (params) => {
    return http.get("/technology/speech_group", { params })
}
//删除知识库分组操作
export let getGroupDel = (params) => {
    return http.post("/technology/group_del", params )
}
//知识库分组编辑操作
export let getGroupEdit = (params) => {
    return http.post("/technology/group_edit", params )
}
